import React from "react";
import { Link } from "react-router-dom";

const bookMyShowUrl = process.env.REACT_APP_BOOK_MY_SHOW_URL;

export default function IncityNavbar() {
  const screenWidth = window.innerWidth;
  
  const redirectToBookMyShow = () => {
    window.location.href = bookMyShowUrl;
  }

  return (
    <div className="parsecGallery nav">
      <a href="http://paraminnovation.org/">
        <div id="logo"></div>{" "}
      </a>
      {screenWidth >= 450 ? (
        <div className="bookTicket" onClick={redirectToBookMyShow}>Book Tickets</div>
      ) : (
        <></>
      )}
      <div className="backButton">
        <p>
          <Link to="/parsec">
            <i
              className="fa-solid fa-chevron-left"
              style={{ color: "black", fontSize: "larger" }}
            >
              <span>Back</span>
            </i>
          </Link>
        </p>
      </div>
    </div>
  );
}
