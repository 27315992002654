import React, { useEffect, useRef, useState } from "react";
import "./FAQContent.css";
import FAQs from "./FAQData";

export default function FAQContent() {
  const [searchQuery, setSearchQuery] = useState("");
  const [expandedIndex, setExpandedIndex] = useState(null);
  // Refs to track the content divs
  const faqRefs = useRef([]); // Initialize an array to hold refs
  const questionRefs = useRef([]);

  // const faqMinHeight = window.innerWidth <= 450 ? "7vh" : "10vh";

  const filteredFAQs = FAQs.filter(
    (faq) =>
      faq.question.toLowerCase().includes(searchQuery.toLowerCase()) ||
      faq.answer.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Update faqRefs array to match the number of filtered FAQs
  useEffect(() => {
    faqRefs.current = faqRefs.current.slice(0, filteredFAQs.length);
    questionRefs.current = questionRefs.current.slice(0, filteredFAQs.length);
  }, [filteredFAQs.length]);

  // Set initial heights of FAQs based on their corresponding question heights
  useEffect(() => {
    questionRefs.current.forEach((questionRef, index) => {
      if (faqRefs.current[index] && questionRef) {
        faqRefs.current[index].style.height = `${questionRef.clientHeight}px`;
      }
    });
  }, [filteredFAQs]); // Run this effect when filteredFAQs change

  const toggleExpand = (index) => {
    const currentFaqRef = faqRefs.current[index];
    const currentQuestionRef = questionRefs.current[index];
    const isCurrentIndexExpanded = expandedIndex === index;
    // First, close any open FAQ section
    if (expandedIndex !== null && faqRefs.current[expandedIndex]) {
      const previousQuestionHeight =
        questionRefs.current[expandedIndex].clientHeight + "px";
      faqRefs.current[expandedIndex].style.height = previousQuestionHeight;
    }

    if (isCurrentIndexExpanded) {
      // Collapse the section
      if (currentFaqRef && currentQuestionRef) {
        currentFaqRef.style.height = currentQuestionRef.clientHeight + "px";
      }
      setExpandedIndex(null);
    } else {
      // Expand the section
      if (currentFaqRef) {
        currentFaqRef.style.height = "auto";
        const height = currentFaqRef.clientHeight + "px";
        currentFaqRef.style.height = currentQuestionRef.clientHeight + "px"; // Reset to question height before expanding
        setTimeout(() => {
          currentFaqRef.style.height = height;
        }, 0); // Delay necessary to allow the browser to reset height before applying the new height
      }
      setExpandedIndex(index);
    }
  };

  return (
    <div className="faqContainer">
      <div className="faqContent">
        <h2>FREQUENTLY ASKED QUESTIONS</h2>
        {/* <p>
          In publishing and graphic design, Lorem ipsum is a placeholder text
          commonly used to demonstrate the visual form of a document or a
          typeface without relying on meaningful
        </p> */}
        <input
          type="text"
          placeholder="Search"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <div className="faqList">
          {filteredFAQs.map((faq, index) => (
            <div
              key={index}
              className={`faq ${expandedIndex === index ? "show" : "hide"}`}
              ref={(el) => (faqRefs.current[index] = el)}
            >
              <hr />
              <div
                ref={(el) => (questionRefs.current[index] = el)}
                className="faqQuestion"
                style={{
                  cursor: "pointer",
                  // marginTop: "10px",
                  color: expandedIndex === index ? "#626264" : "#ffffff",
                  transition: "0.3s ease-in",
                }}
              >
                {faq.question}
              <img
                className={`${expandedIndex === index ? "expanded" : ""}`}
                src="/arrow.svg"
                alt=""
                onClick={() => toggleExpand(index)}
              />
              </div>
              <div className="faqAnswer" style={{ paddingTop: "0" }}>
                {faq.answer}
              </div>
            </div>
          ))}
          <hr style={{ width: "100%" }} />
        </div>
      </div>
    </div>
  );
}
