import React from 'react'

const Keywords = ({keywords}) => {
  return (
    <div className='exhibitPageKeywordsContainer'>
      {keywords.map((keyword, index) => (
        <div key={index}>{keyword}</div>
      ))}
    </div>
  )
}

export default Keywords;