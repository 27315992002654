import React, { useRef } from "react";
import "./ParsecNav.css";
import { Link, useLocation, useNavigate } from "react-router-dom";

const sidebarLinks = [
  { linkName: "Visit Us", redirectUrl: "/parsec/visit-us" },
  // Add more links here
];

const bookMyShowUrl = process.env.REACT_APP_BOOK_MY_SHOW_URL;

export default function ParsecNav() {
  const screenWidth = window.innerWidth;
  const sideBarRef = useRef();
  const sideBarButtonRef = useRef();
  const isClickedRef = useRef(false);
  const navigate = useNavigate(); // useNavigate hook
  const location = useLocation(); // This represents the current URL

  const displayHideSidebar = () => {
    document.body.style.overflowY = isClickedRef.current ? "" : "hidden";
    sideBarRef.current.style.right = isClickedRef.current ? "-100vw" : "0";
    sideBarButtonRef.current.classList.toggle("active");
    sideBarButtonRef.current.classList.toggle("not-active");
    isClickedRef.current = !isClickedRef.current;
  };

  // A function to handle link navigation and sidebar toggle
  const handleNavigation = (redirectUrl) => {
    if (location.pathname !== redirectUrl) {
      navigate(redirectUrl); // Navigate only if different
    }
    displayHideSidebar(); // Always close the sidebar
  };
  const redirectToBookMyShow = () => {
    window.open(bookMyShowUrl, "_blank").focus();
  };

  return (
    <div className="parsec nav box2d">
      <a href="http://paraminnovation.org/">
        <div id="logo"></div>
      </a>
      {screenWidth > 450 ? (
        <div className="visitUsOrBook">
          <Link to="/parsec/visit-us">
            <div className="visitUs">VISIT US</div>
          </Link>
          <div className="bookTicket" onClick={redirectToBookMyShow}>
            Book Tickets
          </div>
        </div>
      ) : (
        <div
          ref={sideBarButtonRef}
          className="sidebarButton btn not-active"
          onClick={displayHideSidebar}
        >
          <span></span>
          <span></span>
          <span></span>
        </div>
      )}
      {screenWidth <= 450 ? (
        <div className="sideBar" ref={sideBarRef}>
          {sidebarLinks.map((link, index) => (
            <div key={index} onClick={() => handleNavigation(link.redirectUrl)}>
              {link.linkName}
            </div>
          ))}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
