const Data = [
  {
    redSrc: "https://openprocessing.org/sketch/1831934",
    altText: "Fractal Fantasia",
    imgSrc:
      "https://digitalassestbucket.s3.amazonaws.com/FunScience/1.+Fractal.webp",
  },
  {
    redSrc: "https://openprocessing.org/sketch/1990191",
    altText: "Fluid Dynamics",
    imgSrc:
      "https://digitalassestbucket.s3.amazonaws.com/FunScience/2.+Fluid+Dynamics.webp",
  },
  {
    redSrc: "https://www.nature.com/immersive/d41586-019-03165-4/index.html",
    altText: "Word Net",
    imgSrc:
      "https://digitalassestbucket.s3.amazonaws.com/FunScience/3.+Word+Dynamics.png",
  },
  {
    redSrc: "https://pudding.cool/2021/03/wine-model/",
    altText: "Wine and Math",
    imgSrc:
      "https://digitalassestbucket.s3.amazonaws.com/FunScience/4.+Mathematical+wine.png",
  },
  {
    redSrc: "https://threatmap.checkpoint.com/",
    altText: "Live Cyber Attacks",
    imgSrc:
      "https://digitalassestbucket.s3.amazonaws.com/FunScience/5.+Real+time+Cyber+Attacks.avif",
  },
  {
    redSrc: "https://thispersondoesnotexist.com/",
    altText: "Fake Humans",
    imgSrc:
      "https://digitalassestbucket.s3.amazonaws.com/FunScience/6.+Person+does+not+exists.webp",
  },
  {
    redSrc: "https://openprocessing.org/sketch/1969734",
    altText: "Curvy Wonders",
    imgSrc:
      "https://digitalassestbucket.s3.amazonaws.com/FunScience/7.Curvy+wonders+.webp",
  },
  {
    redSrc: "https://openprocessing.org/sketch/763432",
    altText: "Micro Marvels",
    imgSrc:
      "https://digitalassestbucket.s3.amazonaws.com/FunScience/8.+Micro+Marvels.png",
  },
  {
    redSrc: "https://www.escapemotions.com/experiments/fluid_fire_3/index.php",
    altText: "Fire Simulator",
    imgSrc:
      "https://digitalassestbucket.s3.amazonaws.com/FunScience/9.+Fire+Simulation.webp",
  },
  {
    redSrc: "https://playback.fm/paper-snowflake-maker",
    altText: "Snowflake Generator",
    imgSrc:
      "https://digitalassestbucket.s3.amazonaws.com/FunScience/10.+Snowflake+Symmetry.png",
  },
  {
    redSrc:
      "https://www.labxchange.org/library/items/lb:LabXchange:a664fc10:lx_simulation:1?fullscreen=true",
    altText: "Protein Folding",
    imgSrc:
      "https://digitalassestbucket.s3.amazonaws.com/FunScience/11.+Protein+Synthesis.png",
  },
  {
    redSrc: "https://phydemo.app/ray-optics/simulator/",
    altText: "Optics Simulator",
    imgSrc:
      "https://digitalassestbucket.s3.amazonaws.com/FunScience/12.+Laser+Art.webp",
  },
  {
    redSrc: "https://www.dartmouth.edu/emlab/gallery/",
    altText: "Electron Microscope art",
    imgSrc:
      "https://digitalassestbucket.s3.amazonaws.com/FunScience/13.+Electron+Microscopy.webp",
  },
  {
    redSrc: "https://xenobiologymuseum.com/celestial-glow/",
    altText: "Alien Museum",
    imgSrc:
      "https://digitalassestbucket.s3.amazonaws.com/FunScience/14.+Alien+Museum.png",
  },
  {
    redSrc: "https://svs.gsfc.nasa.gov/10661",
    altText: "Galaxy Formation",
    imgSrc:
      "https://digitalassestbucket.s3.amazonaws.com/FunScience/15.+Galaxy+Visualizations.png",
  },
  {
    redSrc:
      "https://www.inspiritvr.com/biology/simulations/dna-replication-simulation",
    altText: "DNA Replication",
    imgSrc:
      "https://digitalassestbucket.s3.amazonaws.com/FunScience/16.+DNA+Replication.webp",
  },
  {
    redSrc: "https://www.8thwall.com/imaginarforlearning/demo1",
    altText: "Solar in AR",
    imgSrc:
      "https://digitalassestbucket.s3.amazonaws.com/FunScience/17.+Solar+in+AR.png",
  },
  {
    redSrc: "https://gravitysimulator.org/solar-system",
    altText: "Gravity Simulator",
    imgSrc:
      "https://digitalassestbucket.s3.amazonaws.com/FunScience/18.+Gravity+Simulator.webp",
  },
  {
    redSrc: "https://immersive.biologicalpreparations.com/enhancements",
    altText: "Biological Prep",
    imgSrc:
      "https://digitalassestbucket.s3.amazonaws.com/FunScience/19.+Biological+Preparation.webp",
  },
  {
    redSrc: "https://informationisbeautiful.net/",
    altText: "Information is Beautiful",
    imgSrc:
      "https://digitalassestbucket.s3.amazonaws.com/FunScience/20.+Information+is+Beautiful.webp",
  },
  {
    redSrc: "https://eyes.nasa.gov/apps/asteroids/#/home",
    altText: "Eye on Asteroids",
    imgSrc:
      "https://digitalassestbucket.s3.amazonaws.com/FunScience/21.+Eyes+on+Asteroids.png",
  },
  {
    redSrc: "https://www.sciencevr.com/",
    altText: "Science VR",
    imgSrc:
      "https://digitalassestbucket.s3.amazonaws.com/FunScience/22.+Science+in+VR.webp",
  },
  {
    redSrc:"https://prajwalsouza.github.io/Experiments/Fourier-Transform-Visualization.html",
    altText: "Fourier Transformers",
    imgSrc:
      "https://digitalassestbucket.s3.amazonaws.com/FunScience/23.+Fourier+Transformation.png",
  },
  {
    redSrc:"https://telescope.live/blog/starforge-spectacular-simulation-stars-being-born",
    altText: "Starforge",
    imgSrc:
      "https://digitalassestbucket.s3.amazonaws.com/FunScience/24.+Starforge.png",
  },
  {
    redSrc: "https://astrogallery.com/collections/hall-of-fame",
    altText: "Ancient Gallery",
    imgSrc:
      "https://digitalassestbucket.s3.amazonaws.com/FunScience/25.+Ancient+Gallery.webp",
  },
  {
    redSrc: "https://www.desmos.com/calculator/qkgb3iuchh",
    altText: "Rotating Frames",
    imgSrc:
      "https://digitalassestbucket.s3.amazonaws.com/FunScience/26.+Rotating+Frames.png",
  },
  {
    redSrc: "https://seismo.berkeley.edu/seismo.real.time.map.html",
    altText: "Real-time Earthquakes",
    imgSrc:
      "https://digitalassestbucket.s3.amazonaws.com/FunScience/27.+Real-time+Earthquakes.webp",
  },
  {
    redSrc: "https://platform.leolabs.space/visualization",
    altText: "Low Earth Satellite Tracker",
    imgSrc:
      "https://digitalassestbucket.s3.amazonaws.com/FunScience/28.+Low+Earth+Satellite+Tracker.webp",
  },
  {
    redSrc: "https://esahubble.org/images/archive/top100/",
    altText: "Clicks from Hubble",
    imgSrc:
      "https://digitalassestbucket.s3.amazonaws.com/FunScience/29.+Clicks+from+Hubble.avif",
  },
  {
    redSrc:"https://ideas.ted.com/gallery-the-most-beautiful-bacteria-youll-ever-see/",
    altText: "Beautiful bacterias",
    imgSrc:
      "https://digitalassestbucket.s3.amazonaws.com/FunScience/30.+Beautiful+Bacteria.webp",
  },
  {
    redSrc: "https://eyes.nasa.gov/apps/earth/#/vitalsign?vitalsign=soil_moisture&altid=0&animating=f&start=&end=",
    altText: "Global Soil Moisture Tracker",
    imgSrc:
      "https://digitalassestbucket.s3.amazonaws.com/FunScience/31.+Global+Soil+Moisture+Tracker.webp",
  },
  {
    redSrc: "https://www.beautifulchemistry.net/reaction",
    altText: "Beautiful Chemistry",
    imgSrc:
      "https://digitalassestbucket.s3.amazonaws.com/FunScience/32.+Beautiful+Chemistry.webp",
  },
  {
    redSrc: "https://oeis.org/",
    altText: "Encyclopedia of Sequences",
    imgSrc:
      "https://digitalassestbucket.s3.amazonaws.com/FunScience/33.+Encyclopedia+of+Sequences+.webp",
  },
  {
    redSrc: "https://chemequations.com/en/",
    altText: "Chemical Reactions Generator",
    imgSrc:
      "https://digitalassestbucket.s3.amazonaws.com/FunScience/34.+Chemical+Reactions+Generator.webp",
  },
  {
    redSrc: "https://www.jezzamon.com/fourier/",
    altText: "Learn Fourier Transformations",
    imgSrc:
      "https://digitalassestbucket.s3.amazonaws.com/FunScience/35.+Learn+Fourier+Transformations.png",
  },
  {
    redSrc: "https://en.wikipedia.org/wiki/Lichtenberg_figure",
    altText: "Litchenberg Figures",
    imgSrc:
      "https://digitalassestbucket.s3.amazonaws.com/FunScience/36.+Litchenberg+Figures.png",
  },
  {
    redSrc: "https://pudding.cool/2018/04/birthday-paradox/",
    altText: "The Birthday Paradox Experiment",
    imgSrc:
      "https://digitalassestbucket.s3.amazonaws.com/FunScience/37.+The+Birthday+Paradox+Experiment.png",
  },
  {
    redSrc: "https://pudding.cool/2022/12/emotion-wheel/",
    altText: "Emotion Wheel",
    imgSrc:
      "https://digitalassestbucket.s3.amazonaws.com/FunScience/38.+Emotion+Wheel.png",
  },
  {
    redSrc: "https://www.wolframcloud.com/obj/microsites/code-cards/",
    altText: "Code Cards",
    imgSrc:
      "https://digitalassestbucket.s3.amazonaws.com/FunScience/39.+Wolfarm.png",
  },
  {
    redSrc: "https://neal.fun/",
    altText: "Neal Fun",
    imgSrc:
      "https://digitalassestbucket.s3.amazonaws.com/FunScience/40.+Neal+fun.png",
  },
  {
    redSrc: "https://www.instagram.com/cosmodernism/",
    altText: "Cosmodernism",
    imgSrc:
      "https://digitalassestbucket.s3.amazonaws.com/FunScience/41.+cosmodernism.png",
  },
  {
    redSrc: "https://scaleofuniverse.com/en",
    altText: "Scale of the Universe",
    imgSrc:
      "https://digitalassestbucket.s3.amazonaws.com/FunScience/42.+Scale+of+universe.png",
  },
  {
    redSrc: "https://blog.matthen.com/",
    altText: "Matthen",
    imgSrc:
      "https://digitalassestbucket.s3.amazonaws.com/FunScience/43.+Mathen.png",
  },
  {
    redSrc: "https://ai.google/discover/scienceai/",
    altText: "Google Science",
    imgSrc:
      "https://digitalassestbucket.s3.amazonaws.com/FunScience/44.+Google+Science.png",
  },
  {
    redSrc: "https://puzzel.org/",
    altText: "Puzzle",
    imgSrc:
      "https://digitalassestbucket.s3.amazonaws.com/FunScience/45.+Puzzle.png",
  },
];

export default Data;
