function createAsteroid() {
  // Remove existing asteroid if present
  const existingAsteroid = document.getElementById("asteroid");
  if (existingAsteroid) {
    existingAsteroid.remove();
  }

  const asteroid = document.createElement("img");
  asteroid.id = "asteroid"; // Add an ID for easy access
  asteroid.src = "/EasterEggs/satellite.gif"; // Ensure the path is correct
  asteroid.style.position = "absolute";
  asteroid.style.left = "-100%"; // Start off-screen
  asteroid.style.top = "70%";
  asteroid.style.scale = 2.5;
  asteroid.style.objectFit = "contain";
  asteroid.style.transform = "translateY(-50%)";
  asteroid.style.transition = "all 2s ease-in-out";
  asteroid.style.zIndex = 1;
  document.body.appendChild(asteroid);
  return asteroid;
}

function moveAsteroid(asteroid) {
  asteroid.style.left = "80%"; // Move across to the other side
  // Trigger page shake after the asteroid stops moving
  asteroid.addEventListener(
    "transitionend",
    () => {
      displayCollisionEffect(asteroid);
      shakePage();
      tiltPage();
    },
    { once: true }
  );
}

function displayCollisionEffect(asteroid) {
  const collisionEffect = document.createElement("img");
  collisionEffect.src = "/EasterEggs/collision.gif"; // Ensure the path is correct
  collisionEffect.style.position = "absolute";
  const asteroidRect = asteroid.getBoundingClientRect(); // Get the position of the asteroid in the viewport
  collisionEffect.style.left = `${asteroidRect.left + 100}px`;
  collisionEffect.style.top = `${asteroidRect.top + 150}px`;
  collisionEffect.style.scale = '7'; // Set a specific size or scale
  collisionEffect.style.transition = "opacity 2s";
  collisionEffect.style.opacity = 1; // Start fully visible
  collisionEffect.style.zIndex = 1;
  document.body.appendChild(collisionEffect);

  // Remove asteroid image and fade out collision effect
  asteroid.remove();
  setTimeout(() => {
    collisionEffect.style.opacity = 0; // Gradually fade out
    setTimeout(() => {
      collisionEffect.remove();
    }, 300); // Remove from DOM after fade
  }, 300); // Start fading shortly after displaying
}

function shakePage() {
  document.body.classList.add("shake");
  setTimeout(() => {
    document.body.classList.remove("shake");
  }, 500); // Adjust time as needed
}

function tiltPage() {
  const container = document.getElementById("main-container"); // Adjust the ID as necessary
  container.style.transition = "transform 0.2s";
  container.style.transform = "rotate(2deg)";
  setTimeout(() => {
    container.style.transform = "rotate(0deg)";
  }, 200); // Reset tilt after shaking
}

function setupShakeCSS() {
  const css = `
      @keyframes shake {
        0%, 100% { transform: translateX(0); }
        25% { transform: translateX(-5px); }
        50% { transform: translateX(5px); }
        75% { transform: translateX(-5px); }
      }
  
      .shake {
        animation: shake 0.5s;
        animation-iteration-count: 1;
      }
    `;
  const head = document.head || document.getElementsByTagName("head")[0];
  const style = document.createElement("style");
  head.appendChild(style);
  style.appendChild(document.createTextNode(css));
}

function triggerEasterEgg() {
  setupShakeCSS();
  const asteroid = createAsteroid();
  setTimeout(() => {
    moveAsteroid(asteroid);
    // shakePage();
  }, 100); // Start moving after a short delay
}

export { triggerEasterEgg };
