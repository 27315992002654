import React, { useEffect } from "react";
import "./Structure.css";
import Keywords from "./Keywords";
import ExhibitData from "./ExhibitData";
import Footer from "../../footer/Footer";
// import placeholder from "../../../../public/placeholder.svg";

const Structure = ({ exhibit, className }) => {
  const pageData = ExhibitData[exhibit];
  // console.log(pageData)
  const screenWidth = window.innerWidth;
  useEffect(() => {
    document.body.style.setProperty("background-color", "#fafafa", "important");
    return () => {
      document.body.style.backgroundColor = "black";
    };
  });
  return (
    <div className={`${className} exhibitPage`}>
      <div className="exhibitPageContent">
        <div className="exhibitPageHead">
          <h1>{pageData.title}</h1>
          <p>{pageData.tag}</p>
          <div
            className="exhibitPageHeadBanner"
            style={{
              backgroundImage: pageData.bannerImage
                ? `url(${pageData.bannerImage})`
                : `url(/placeholder.svg)`,
            }}
          ></div>
        </div>
        {screenWidth <= 450 ? (
          <div className="exhibitPageSubcontent">
            <div className="exhibitPageData">
              <div className="exhibitPageKeywords">
                <Keywords keywords={pageData.keywords} />
              </div>
              <div
                className="exhibitPageSubcontentBanner"
                // style={{ backgroundImage: `url(${pageData.bannerImage2})` }}
                style={{
                  backgroundImage: pageData.bannerImage
                    ? `url(${pageData.bannerImage})`
                    : `url(/placeholder.svg)`,
                }}
              ></div>
              <div className="exhibitPageScienceRelevancy">
                <h3>SCIENCE RELEVANCY</h3>
                <p
                  dangerouslySetInnerHTML={{
                    __html: pageData.scienceRelevancy,
                  }}
                ></p>
              </div>
              <div className="exhibitPageKeyTakeAway">
                <h3>KEY TAKE AWAY</h3>
                <p
                  dangerouslySetInnerHTML={{ __html: pageData.keyTakeAway }}
                ></p>
              </div>
            </div>
          </div>
        ) : (
          <div className="exhibitPageSubcontent">
            <div className="exhibitPageData">
              <div className="exhibitPageKeywords">
                <Keywords keywords={pageData.keywords} />
              </div>
              <div className="exhibitPageScienceRelevancy">
                <h3>SCIENCE RELEVANCY</h3>
                <p
                  dangerouslySetInnerHTML={{
                    __html: pageData.scienceRelevancy,
                  }}
                ></p>
              </div>
              <div className="exhibitPageKeyTakeAway">
                <h3>KEY TAKE AWAY</h3>
                <p
                  dangerouslySetInnerHTML={{ __html: pageData.keyTakeAway }}
                ></p>
              </div>
            </div>
            <div
              className="exhibitPageSubcontentBanner"
              style={{
                backgroundImage: pageData.bannerImage
                  ? `url(${pageData.bannerImage})`
                  : `url(/placeholder.svg)`,
              }}
            ></div>
          </div>
        )}
        {pageData.ytLink && (
          <div className="exhibitPageYtLink">
            <h3>LINKS</h3>
            <p>
              <a href={pageData.ytLink}>{pageData.ytLink}</a>
            </p>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default Structure;
