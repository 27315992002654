import React, { useState } from "react";
import "./FAQForParsec.css";
import FAQs from "./FAQData";
import { Link } from "react-router-dom";

export default function FAQForParsec() {
  const topThreeFAQs = FAQs.slice(0, 3);

  // State to track the index of the currently expanded FAQ, or null if none are expanded
  const [expandedIndex, setExpandedIndex] = useState(null);

  const toggleExpand = (index) => {
    // Collapse the currently expanded FAQ if it's clicked again or expand the new one
    setExpandedIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <div className="faqForParsec">
      <div className="faqForParsecContent">
        <h2 style={{ fontWeight: "bold" }}>FAQ'S</h2>
        {topThreeFAQs.map((faq, index) => (
          <div
            className={`faqs ${expandedIndex === index ? "show" : "hide"}`}
            key={index}
          >
            <hr />
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <p
                className="faQuestion"
                style={{
                  color: expandedIndex === index ? "#626264" : "#ffffff",
                }}
              >
                {faq.question}
              </p>
            </div>
            <p className="faqAnswer">{faq.answer}</p>
            <img
              className={`${expandedIndex === index ? "expanded" : ""}`}
              src="/arrow.svg"
              alt=""
              onClick={() => toggleExpand(index)}
            />
          </div>
        ))}
        <hr />
        <div className="seeMoreFAQs">
          <div className="seeMoreButton">
            {" "}
            <Link to={"/parsec/faqs"}>SEE MORE</Link>
          </div>
        </div>
      </div>
    </div>
  );
}
