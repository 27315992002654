import React from "react";
import { Link } from "react-router-dom";

export default function CustomNav() {
  return (
    <div className="nav">
      <div id="logo">
        <a href="http://paraminnovation.org/"></a>
      </div>
      <div id="fos-logo"></div>
    </div>
  );
}
