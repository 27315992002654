import React, { useCallback, useEffect, useState } from "react";
import cardData from "./cardData";
import CarouselComponent from "./CarouselComponent";
import { Link } from "react-router-dom";
import { triggerEasterEgg } from "./EasterEggs/nasa/NasaDartMission";
 
const bookMyShowUrl = process.env.REACT_APP_BOOK_MY_SHOW_URL;

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

// Function to convert month number to month name
const getMonthName = (monthNumber) => {
  return monthNames[monthNumber];
};

// extractDate function to return an object with day and monthName
const extractDateParts = (dateString) => {
  // console.log(dateString);
  const date = new Date(dateString);
  // console.log(date);
  const day = date.getDate(); // Day of the month
  // console.log(day);
  const monthNumber = date.getMonth(); // Month number
  // console.log(monthNumber);
  const monthName = getMonthName(monthNumber); // Convert month number to month name
  // console.log(monthName);
  return { day, monthName };
};

// Function to convert 24-hour time to 12-hour format and return individual parts
const convertToTimeParts = (time) => {
  let [hours, minutes] = time.split(":").map(Number); // Convert hours and minutes to numbers
  const period = hours >= 12 ? "PM" : "AM";
  hours = hours % 12 || 12; // Convert 0 to 12 for 12-hour format

  // console.log(hours, minutes, period)

  // Ensure two digits
  const hoursFormatted = hours.toString().padStart(2, "0");
  const minutesFormatted = minutes.toString().padStart(2, "0");

  // Return individual time parts
  return {
    hoursTens: hoursFormatted[0],
    hoursOne: hoursFormatted[1],
    minutesTens: minutesFormatted[0],
    minutesOne: minutesFormatted[1],
    period,
  };
};

const numbers = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];

export default function Content() {
  const screenWidth = window.innerWidth;
  const [currentIndex, setCurrentIndex] = useState(0);
  const [events, setEvents] = useState(null);
  const [currentEvent, setCurrentEvent] = useState(null);
  const [startDateParts, setStartDateParts] = useState({
    day: "",
    monthName: "",
  });
  const [endDateParts, setEndDateParts] = useState({ day: "", monthName: "" });
  const [subBannerStyle, setSubBannerStyle] = useState({});
  const [bannerStyle, setbannerStyle] = useState({});
  const [startTimeParts, setStartTimeParts] = useState({
    hoursOne: "",
    hoursTens: "",
    minutesOne: "",
    minutesTens: "",
    period: "",
  });
  const [endTimeParts, setEndTimeParts] = useState({
    hoursOne: "",
    hoursTens: "",
    minutesOne: "",
    minutesTens: "",
    period: "",
  });

  const [startTouch, setStartTouch] = useState(null);

  // FOR INITIATING EASTER SIMULATION
  // const [isGravityOn, setIsGravityOn] = useState(false);
  // const [isRippleOn, setIsRippleOn] = useState(false);

  const redirectToBookMyShow = (url) => {
    const destinationUrl = url || bookMyShowUrl; // Use the provided URL or fall back to bookMyShowUrl
    window.open(destinationUrl, "_blank").focus();
  };

  // HANDLE NEXT BUTTON CLICK
  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % events.length);
  };

  // HANDLE PREVIOUS BUTTON CLICK
  const handlePrev = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + events.length) % events.length
    );
  };

  // INITIATING FADEIN/OUT ANIMATION FOR BANNER
  const initiateTransition = useCallback(
    (newIndex) => {
      // Step 1: Change z-index of subBannerContainer to be on top
      if (events) {
        setSubBannerStyle({
          backgroundImage:
            screenWidth > 450
              ? `linear-gradient(rgba(11, 12, 16, 0.2), rgba(11, 12, 16, 0.4), rgba(11, 12, 16, 0.6), rgb(11, 12, 16, 0.8), rgb(11, 12, 16)), url(${events[newIndex]?.photoVideo})`
              : `linear-gradient(rgba(11, 12, 16, 0.2), rgba(11, 12, 16, 0.6), rgba(11, 12, 16, 0.9), rgb(11, 12, 16, 1)), url(${events[newIndex]?.photoVideo})`,
          zIndex: 1, // Ensure this is above the main image
          opacity: 1,
        });

        // Step 2: After transition (assuming 1s duration), update main image and reset subBannerContainer
        setTimeout(() => {
          setbannerStyle({
            backgroundImage:
              screenWidth > 450
                ? `linear-gradient(rgba(11, 12, 16, 0.2), rgba(11, 12, 16, 0.4), rgba(11, 12, 16, 0.6), rgb(11, 12, 16, 0.8), rgb(11, 12, 16)), url(${events[newIndex]?.photoVideo})`
                : `linear-gradient(rgba(11, 12, 16, 0.2), rgba(11, 12, 16, 0.6), rgba(11, 12, 16, 0.9), rgb(11, 12, 16)), url(${events[newIndex]?.photoVideo})`,
            zIndex: 1, // Ensure this is above the main image
            opacity: 1,
          });
          setSubBannerStyle({
            zIndex: -1, // Reset z-index to be below main image
            opacity: 0,
          });
        }, 800); // Match this with your CSS transition duration
      }
    },
    [events, screenWidth]
  );

  // FUNCTION TO HANDLE THE START OF A TOUCH
  const handleTouchStart = (e) => {
    const touch = e.touches[0];
    setStartTouch({ x: touch.clientX, y: touch.clientY });
  };

  // FUNCTION TO HANDLE THE END OF A TOUCH
  const handleTouchMove = (e) => {
    if (!startTouch) return;

    const touch = e.changedTouches[0];
    const endTouch = { x: touch.clientX, y: touch.clientY };

    const diffX = startTouch.x - endTouch.x;
    const diffY = startTouch.y - endTouch.y;

    if (Math.abs(diffX) > Math.abs(diffY)) {
      // Ensure horizontal swipe
      if (diffX > 0) {
        // Swiped left
        handleNext();
      } else {
        // Swiped right
        handlePrev();
      }
    }

    // Reset start touch
    setStartTouch(null);
  };

  // FETCH DATA FROM API
  useEffect(() => {
    const requestOptions = {
      method: "GET",
      redirect: "follow",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    };

    fetch(`${process.env.REACT_APP_API_ENDPOINT}/latestThree`, requestOptions)
      .then((response) => response.json())
      .then((result) => setEvents(result))
      .catch((error) => console.error(error));
  }, []);

  // SETTING DATA FROM API TO A STATE VARIABLE
  useEffect(() => {
    initiateTransition(currentIndex);
    if (events) {
      setCurrentEvent(events[currentIndex]);
    }
  }, [events, currentIndex, initiateTransition]);

  // EXTRACTING DATE RELATED DATA FOR ANIMATION
  useEffect(() => {
    if (currentEvent) {
      setCurrentEvent(currentEvent);
      // console.log(currentEvent.formattedFromDate)
      // console.log(currentEvent.formattedToDate)
      var startDateParts = extractDateParts(currentEvent.formattedFromDate);
      setStartDateParts({
        day: startDateParts.day,
        monthName: startDateParts.monthName,
      });
      var endDateParts = extractDateParts(currentEvent.formattedToDate);
      setEndDateParts({
        day: endDateParts.day,
        monthName: endDateParts.monthName,
      });

      if (currentEvent.time && currentEvent.endTime) {
        const parts1 = convertToTimeParts(currentEvent.time);
        setStartTimeParts(parts1);
        const parts2 = convertToTimeParts(currentEvent.endTime);
        setEndTimeParts(parts2);
      }
    }
  }, [currentEvent, setCurrentEvent]);

  // useEffect(() => {
  //   if (window.jQuery) {
  //     console.log("debugging " ,jquery);
  //     jquery('.ripple-element').ripples({
  //       resolution: 256,
  //       perturbance: 0.01
  //     });
  //   } else {
  //     console.error("jQuery not loaded");
  //   }
  // }, []);

  // GRAVITY SIMULATION ON ELEMENTS
  // const addGravity = () => {
  //   const loadScript = (src) => {
  //     return new Promise((resolve, reject) => {
  //       const script = document.createElement("script");
  //       script.src = src;
  //       script.async = false; // Ensure scripts load in the order they are added
  //       script.onload = resolve;
  //       script.onerror = () =>
  //         reject(new Error(`Script load error for ${src}`));
  //       document.body.appendChild(script);
  //     });
  //   };

  //   if (!isGravityOn) {
  //     // Ensure Box2D loads first
  //     loadScript("/gravity/box-2d.js")
  //       .then(() => {
  //         console.log("Box2D loaded successfully");
  //         return loadScript("/gravity/gravity.js"); // Load gravity.js after Box2D
  //       })
  //       .then(() => {
  //         console.log("GravityJS loaded successfully");
  //         console.log(
  //           "Both scripts loaded and gravity initialized successfully."
  //         );
  //         setIsGravityOn(true);
  //       })
  //       .catch((error) => {
  //         console.error(error);
  //         setIsGravityOn(false);
  //       });
  //   }
  // };

  // Translate Animation for Start Date
  let [startDate1, startDate2] = startDateParts.day
    .toString()
    .split("")
    .map(Number);
  if (!startDate2) {
    startDate2 = startDate1;
    startDate1 = 0;
  }
  const startDateTranslateOnes =
    screenWidth > 450
      ? `translateY(${-8 * startDate2}vh)`
      : `translateY(${-5 * startDate2}vh)`;
  const startDateTranslateTens =
    screenWidth > 450
      ? `translateY(${-8 * startDate1}vh)`
      : `translateY(${-5 * startDate1}vh)`;

  // Translate Animation for MonthNames
  const startMonthIndex = monthNames.indexOf(startDateParts.monthName);
  const translateStartMonth =
    screenWidth > 450
      ? `translateY(${-3.5 * startMonthIndex}vh)`
      : `translateY(${-1.8 * startMonthIndex}vh)`;

  // Translate Animation for End Date
  let [endDate1, endDate2] = endDateParts.day.toString().split("").map(Number);
  if (!endDate2) {
    endDate2 = endDate1;
    endDate1 = 0;
  }
  const endDateTranslateOnes =
    screenWidth > 450
      ? `translateY(${-8 * endDate2}vh)`
      : `translateY(${-5 * endDate2}vh)`;
  const endDateTranslateTens =
    screenWidth > 450
      ? `translateY(${-8 * endDate1}vh)`
      : `translateY(${-5 * endDate1}vh)`;

  // Translate Animation for MonthNames
  const endMonthIndex = monthNames.indexOf(endDateParts.monthName);
  const translateEndMonth =
    screenWidth > 450
      ? `translateY(${-3.5 * endMonthIndex}vh)`
      : `translateY(${-1.8 * endMonthIndex}vh)`;

  // Assuming startTimeParts contains hoursTens, hoursOne, minutesTens, minutesOne, and period
  // And assuming numbers array is [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]

  // For hours and minutes, convert the current part value to its index times -5
  const translateStartHoursTens =
    screenWidth > 450
      ? `translateY(${-2.6 * parseInt(startTimeParts.hoursTens, 10)}vh)`
      : `translateY(${-2 * parseInt(startTimeParts.hoursTens, 10)}vh)`;
  const translateStartHoursOnes =
    screenWidth > 450
      ? `translateY(${-2.6 * parseInt(startTimeParts.hoursOne, 10)}vh)`
      : `translateY(${-2 * parseInt(startTimeParts.hoursOne, 10)}vh)`;
  const translateStartMinutesTens =
    screenWidth > 450
      ? `translateY(${-2.6 * parseInt(startTimeParts.minutesTens, 10)}vh)`
      : `translateY(${-2 * parseInt(startTimeParts.minutesTens, 10)}vh)`;
  const translateStartMinutesOnes =
    screenWidth > 450
      ? `translateY(${-2.6 * parseInt(startTimeParts.minutesOne, 10)}vh)`
      : `translateY(${-2 * parseInt(startTimeParts.minutesOne, 10)}vh)`;

  // For Start Date AM/PM, determine if it's AM or PM and calculate translateY accordingly
  const translateStartDateAmPm =
    startTimeParts.period === "AM"
      ? "translateY(0vh)"
      : screenWidth > 450
      ? "translateY(-2.6vh)"
      : "translateY(-2vh)";

  // Assuming endTimeParts contains hoursTens, hoursOne, minutesTens, minutesOne, and period
  // And assuming numbers array is [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]

  // For hours and minutes, convert the current part value to its index times -5
  const translateEndHoursTens =
    screenWidth > 450
      ? `translateY(${-2.6 * parseInt(endTimeParts.hoursTens, 10)}vh)`
      : `translateY(${-2 * parseInt(endTimeParts.hoursTens, 10)}vh)`;
  const translateEndHoursOnes =
    screenWidth > 450
      ? `translateY(${-2.6 * parseInt(endTimeParts.hoursOne, 10)}vh)`
      : `translateY(${-2 * parseInt(endTimeParts.hoursOne, 10)}vh)`;
  const translateEndMinutesTens =
    screenWidth > 450
      ? `translateY(${-2.6 * parseInt(endTimeParts.minutesTens, 10)}vh)`
      : `translateY(${-2 * parseInt(endTimeParts.minutesTens, 10)}vh)`;
  const translateEndMinutesOnes =
    screenWidth > 450
      ? `translateY(${-2.6 * parseInt(endTimeParts.minutesOne, 10)}vh)`
      : `translateY(${-2 * parseInt(endTimeParts.minutesOne, 10)}vh)`;

  // For Start Date AM/PM, determine if it's AM or PM and calculate translateY accordingly
  const translateEndDateAmPm =
    endTimeParts.period === "AM"
      ? "translateY(0vh)"
      : screenWidth > 450
      ? "translateY(-2.6vh)"
      : "translateY(-2vh)";

  return (
    <div className="incityContent ripple-element" id="main-container">
      <div className="incityCarousel">
        {screenWidth > 450 ? (
          <iframe
            className="video-iframe"
            src="https://www.youtube.com/embed/aq_BzbMULsA?autoplay=1&loop=1&playlist=aq_BzbMULsA&controls=0&mute=1"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        ) : (
          <iframe
            className="video-iframe"
            src="https://www.youtube.com/embed/VhciwgwUfE4?autoplay=1&loop=1&playlist=VhciwgwUfE4&controls=0&mute=1"
            title="Parsec Jayanagar Vertical"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        )}
        <div className="video-overlay"></div>
        <div className="incityContentHead">
          <div className="incityContentHeadTitle">
            <h1 className="parsec box2d addRipple" onClick={triggerEasterEgg}>
              PARSEC
            </h1>
            <hr className="box2d" />
            <p className="box2d">EXPERIENCE SCIENCE LIKE NEVER BEFORE</p>
            {screenWidth <= 450 ? (
              <div
                className="bookTicket"
                onClick={() => redirectToBookMyShow(null)}
              >
                Book Tickets
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
      <div className="incityContent90">
        <div className="incityGalleryContent">
          <h1>NOW TRENDING</h1>
          <p>
            No more dusty relics; enter the Paramverse with 6 vibrant galaxies
            brimming with over 50 mind-blowing exhibits.
            {screenWidth > 450 ? <></> : <br />} It's not a museum; it's a
            playground for curiosity, where science explodes into a thrilling
            day out fueled by discovery, wonder and fun engaging events.
          </p>
        </div>
        <h2>GALLERIES</h2>
        <CarouselComponent cardData={cardData} />
        {/* {console.log(currentEvent)} */}
        {currentEvent && (
          <div
            className="highlights"
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
          >
            <h2>HIGHLIGHTS</h2>
            <div className="highlightsContent">
              <div className="bannerContainer" style={bannerStyle}>
                {events && (
                  <div
                    className="subBannerContainer"
                    style={subBannerStyle}
                  ></div>
                )}
                <div className="titleDescriptionAndBookButton">
                  <h2>{currentEvent.title}</h2>
                  <Link
                    style={{ textDecoration: "none" }}
                    to={`/parsec/highlights/${currentEvent._id}`}
                  >
                    <p>
                      {
                        currentEvent?.description
                          .split(" ") // Split the description into words
                          .slice(0, 20) // Take the first 20 words
                          .join(" ") // Join them back into a string
                      }
                      <span>...See more</span>
                    </p>
                  </Link>
                  <div
                    className="bookButton"
                    onClick={() =>
                      redirectToBookMyShow(currentEvent.bookMyShowUrl)
                    }
                  >
                    BOOK TICKETS
                  </div>
                </div>
                <div className="dateTimeAndNavigation">
                  <div className="startNEndDate">
                    <div>
                      <div className="date">
                        <p className="startEndLabel">Start</p>
                        <div
                          className="datenDay"
                          style={{
                            display: "flex",
                            width: "100%",
                            justifyContent: "center",
                          }}
                        >
                          <div
                            id="dateTens"
                            style={{ transform: startDateTranslateTens }}
                          >
                            <h1>0</h1>
                            <h1>1</h1>
                            <h1>2</h1>
                          </div>
                          <div
                            id="dateOnes"
                            style={{ transform: startDateTranslateOnes }}
                          >
                            {numbers.map((number) => (
                              <h1 key={number}>{number}</h1>
                            ))}
                          </div>
                        </div>
                        <div
                          className="month"
                          style={{
                            height: screenWidth > 450 ? "3.5vh" : "1.8vh",
                            width: "100%",
                            textAlign: "center",
                            overflow: "hidden",
                          }}
                        >
                          <div
                            style={{
                              transform: translateStartMonth,
                              width: "100%",
                            }}
                          >
                            {monthNames.map((month) => (
                              <p
                                key={month}
                                style={{
                                  textTransform: "uppercase",
                                  height: screenWidth > 450 ? "3.5vh" : "1.8vh",
                                }}
                              >
                                {screenWidth <= 450
                                  ? month.substring(0, 3)
                                  : month}
                              </p>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div>
                      <div className="date">
                        <p className="startEndLabel">End</p>
                        <div
                          className="datenDay"
                          style={{
                            display: "flex",
                            width: "100%",
                            justifyContent: "center",
                          }}
                        >
                          <div
                            id="dateTens"
                            style={{ transform: endDateTranslateTens }}
                          >
                            <h1>0</h1>
                            <h1>1</h1>
                            <h1>2</h1>
                          </div>
                          <div
                            id="dateOnes"
                            style={{ transform: endDateTranslateOnes }}
                          >
                            {numbers.map((number) => (
                              <h1 key={number}>{number}</h1>
                            ))}
                          </div>
                        </div>
                        <div
                          className="month"
                          style={{
                            height: screenWidth > 450 ? "3.5vh" : "1.8vh",
                            width: "100%",
                            textAlign: "center",
                            overflow: "hidden",
                          }}
                        >
                          <div
                            style={{
                              transform: translateEndMonth,
                              width: "100%",
                            }}
                          >
                            {monthNames.map((month) => (
                              <p
                                key={month}
                                style={{
                                  textTransform: "uppercase",
                                  height: screenWidth > 450 ? "3.5vh" : "1.8vh",
                                }}
                              >
                                {screenWidth <= 450
                                  ? month.substring(0, 3)
                                  : month}
                              </p>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="time">
                    {screenWidth > 450 ? (
                      <>
                        <div
                          className="timeContainer"
                          style={{
                            height: screenWidth > 450 ? "2.6vh" : "2vh",
                            display: "flex",
                            overflow: "hidden",
                            // justifyContent: "space-between",
                            width: screenWidth > 450 ? "55" : "45%",
                            flexWrap: screenWidth <= 450 && "wrap",
                            gap: "1%",
                          }}
                        >
                          <div
                            id="hoursTens"
                            style={{
                              width: "max-content",
                              transform: translateStartHoursTens,
                            }}
                          >
                            <h1>0</h1>
                            <h1>1</h1>
                          </div>
                          <div
                            id="hoursOnes"
                            style={{
                              width: "max-content",
                              transform: translateStartHoursOnes,
                            }}
                          >
                            {" "}
                            {/*style={{ transform: translateOnes }}*/}
                            {numbers.map((number) => (
                              <h1 key={number}>{number}</h1>
                            ))}
                          </div>
                          <h1>:</h1>
                          <div
                            id="minutesTens"
                            style={{
                              width: "max-content",
                              transform: translateStartMinutesTens,
                            }}
                          >
                            <h1>0</h1>
                            <h1>1</h1>
                            <h1>2</h1>
                            <h1>3</h1>
                            <h1>4</h1>
                            <h1>5</h1>
                          </div>
                          <div
                            id="minutesOnes"
                            style={{
                              width: "max-content",
                              transform: translateStartMinutesOnes,
                            }}
                          >
                            {" "}
                            {/*style={{ transform: translateOnes }}*/}
                            {numbers.map((number) => (
                              <h1 key={number}>{number}</h1>
                            ))}
                          </div>
                          <div
                            id="am-pm"
                            style={{
                              width: "max-content",
                              transform: translateStartDateAmPm,
                            }}
                          >
                            <h1>AM</h1>
                            <h1>PM</h1>
                          </div>
                        </div>
                        <h2 style={{ margin: "0" }}>-</h2>
                        <div
                          className="timeContainer"
                          style={{
                            height: screenWidth > 450 ? "2.6vh" : "2vh",
                            display: "flex",
                            overflow: "hidden",
                            // justifyContent: "space-between",
                            width: screenWidth > 450 ? "55" : "45%",
                            flexWrap: screenWidth <= 450 && "wrap",
                            gap: "1%",
                          }}
                        >
                          <div
                            id="hoursTens"
                            style={{
                              width: "max-content",
                              transform: translateEndHoursTens,
                            }}
                          >
                            <h1>0</h1>
                            <h1>1</h1>
                          </div>
                          <div
                            id="hoursOnes"
                            style={{
                              width: "max-content",
                              transform: translateEndHoursOnes,
                            }}
                          >
                            {" "}
                            {/*style={{ transform: translateOnes }}*/}
                            {numbers.map((number) => (
                              <h1 key={number}>{number}</h1>
                            ))}
                          </div>
                          <h1>:</h1>
                          <div
                            id="minutesTens"
                            style={{
                              width: "max-content",
                              transform: translateEndMinutesTens,
                            }}
                          >
                            <h1>0</h1>
                            <h1>1</h1>
                            <h1>2</h1>
                            <h1>3</h1>
                            <h1>4</h1>
                            <h1>5</h1>
                          </div>
                          <div
                            id="minutesOnes"
                            style={{
                              width: "max-content",
                              transform: translateEndMinutesOnes,
                            }}
                          >
                            {" "}
                            {/*style={{ transform: translateOnes }}*/}
                            {numbers.map((number) => (
                              <h1 key={number}>{number}</h1>
                            ))}
                          </div>
                          <div
                            id="am-pm"
                            style={{
                              width: "max-content",
                              transform: translateEndDateAmPm,
                            }}
                          >
                            <h1>AM</h1>
                            <h1>PM</h1>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div
                          style={{
                            display: "flex",
                            // flexDirection: "column",
                            width: "40%",
                            justifyContent: "flex-end",
                            gap: "5%",
                          }}
                        >
                          <div
                            style={{
                              height: screenWidth > 450 ? "5vh" : "2vh",
                              display: "flex",
                              overflow: "hidden",
                              justifyContent: "space-between",
                              flexWrap: screenWidth <= 450 && "wrap",
                              width: screenWidth <= 450 && "100%",
                            }}
                          >
                            <div
                              id="hoursTens"
                              style={{
                                width: "max-content",
                                transform: translateStartHoursTens,
                              }}
                            >
                              <h1>0</h1>
                              <h1>1</h1>
                            </div>
                            <div
                              id="hoursOnes"
                              style={{
                                width: "max-content",
                                transform: translateStartHoursOnes,
                              }}
                            >
                              {" "}
                              {/*style={{ transform: translateOnes }}*/}
                              {numbers.map((number) => (
                                <h1 key={number}>{number}</h1>
                              ))}
                            </div>
                            <h1>:</h1>
                            <div
                              id="minutesTens"
                              style={{
                                width: "max-content",
                                transform: translateStartMinutesTens,
                              }}
                            >
                              <h1>0</h1>
                              <h1>1</h1>
                              <h1>2</h1>
                              <h1>3</h1>
                              <h1>4</h1>
                              <h1>5</h1>
                            </div>
                            <div
                              id="minutesOnes"
                              style={{
                                width: "max-content",
                                transform: translateStartMinutesOnes,
                              }}
                            >
                              {" "}
                              {/*style={{ transform: translateOnes }}*/}
                              {numbers.map((number) => (
                                <h1 key={number}>{number}</h1>
                              ))}
                            </div>
                          </div>
                          <div
                            style={{
                              height: "2vh",
                              width: "inherit",
                              overflow: "hidden",
                            }}
                          >
                            <div
                              id="am-pm"
                              style={{
                                width: "100%",
                                transform: translateStartDateAmPm,
                                height: "2vh",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                            >
                              <h1>AM</h1>
                              <h1>PM</h1>
                            </div>
                          </div>
                        </div>
                        <h2>-</h2>
                        <div
                          style={{
                            display: "flex",
                            // flexDirection: "column",
                            width: "40%",
                            justifyContent: "flex-end",
                            gap: "5%",
                          }}
                        >
                          <div
                            style={{
                              height: screenWidth > 450 ? "5vh" : "2vh",
                              display: "flex",
                              overflow: "hidden",
                              justifyContent: "space-between",
                              flexWrap: screenWidth <= 450 && "wrap",
                              width: screenWidth <= 450 && "100%",
                            }}
                          >
                            <div
                              id="hoursTens"
                              style={{
                                width: "max-content",
                                transform: translateEndHoursTens,
                              }}
                            >
                              <h1>0</h1>
                              <h1>2</h1>
                            </div>
                            <div
                              id="hoursOnes"
                              style={{
                                width: "max-content",
                                transform: translateEndHoursOnes,
                              }}
                            >
                              {" "}
                              {/*style={{ transform: translateOnes }}*/}
                              {numbers.map((number) => (
                                <h1 key={number}>{number}</h1>
                              ))}
                            </div>
                            <h1>:</h1>
                            <div
                              id="minutesTens"
                              style={{
                                width: "max-content",
                                transform: translateEndMinutesTens,
                              }}
                            >
                              <h1>0</h1>
                              <h1>1</h1>
                              <h1>2</h1>
                              <h1>3</h1>
                              <h1>4</h1>
                              <h1>5</h1>
                            </div>
                            <div
                              id="minutesOnes"
                              style={{
                                width: "max-content",
                                transform: translateEndMinutesOnes,
                              }}
                            >
                              {" "}
                              {/*style={{ transform: translateOnes }}*/}
                              {numbers.map((number) => (
                                <h1 key={number}>{number}</h1>
                              ))}
                            </div>
                          </div>
                          <div
                            style={{
                              height: "2vh",
                              width: "inherit",
                              overflow: "hidden",
                            }}
                          >
                            <div
                              id="am-pm"
                              style={{
                                width: "100%",
                                transform: translateEndDateAmPm,
                                height: "2vh",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                            >
                              <h1>AM</h1>
                              <h1>PM</h1>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                  {screenWidth > 450 && (
                    <div className="navigation" style={{}}>
                      <div
                        className={
                          events.length > 1
                            ? "previous hoverEffect"
                            : "previous"
                        }
                        onClick={handlePrev}
                      >
                        <svg
                          style={{
                            height: "40%",
                            opacity: events.length > 1 ? "1" : "0.2",
                          }}
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 320 512"
                        >
                          <path
                            fill="#ffffff"
                            d="M41.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 256 246.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z"
                          />
                        </svg>
                      </div>
                      <div
                        className={
                          events.length > 1 ? "next hoverEffect" : "next"
                        }
                        onClick={handleNext}
                      >
                        <svg
                          style={{
                            height: "40%",
                            opacity: events.length > 1 ? "1" : "0.2",
                          }}
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 320 512"
                        >
                          <path
                            fill="#ffffff"
                            d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z"
                          />
                        </svg>
                      </div>
                    </div>
                  )}
                </div>
                {events.length > 1 && (
                  <div className="highlightIndicators">
                    <div className="indicatorContainer">
                      <ul
                        style={{ display: "flex", margin: "0", padding: "0" }}
                      >
                        {events.map((event, index) => (
                          <li
                            key={index}
                            className={currentIndex === index ? "active" : ""}
                            onClick={() => setCurrentIndex(index)}
                          >
                            {/* Optionally, display something inside the indicator */}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
