const GuidelinesData = [
  {
    heading: "Booking and Scheduling:",
    content: `Institutions/organisations must confirm the date of the visit and the number of attendees by call or email at least two weeks before the visit. After the availability of slots is confirmed, payment can be made through NEFT or UPI.<br /><br /> Ph.no: +91 73385 80197 Email ID: marketing@paraminnovation.org <br /><br />Cancellation or rescheduling must be done at least one week in advance.`,
  },
  {
    heading: "Open Hours for group tours:",
    content: `Tuesday to Friday: 10:00 am to 1:00 pm`,
  },
  {
    heading: "Slot duration:",
    content: `Bookings are done in slots of 1-1.5 HR<br /<br /> We can accommodate 35 visitors at the most per slot. Slots are only confirmed after full payment of the entry fee, and any change in slots is subject to availability only.`,
  },
  {
    heading: "Safety and Liability:",
    content: `All attendees must follow our safety guidelines. Param is not liable for accidents or injuries due to non-compliance with safety instructions.`,
  },
  {
    heading: "Code of conduct:",
    content: `To maintain a fun atmosphere, refrain from any conduct that is harmful or disrespectful to you, your fellow visitors, or PARSEC staff.`,
  },
  {
    heading: "Restrictions:",
    content: `We are a food and beverage-free zone. We are a smoking, alcohol and drug-free zone.`,
  },
  {
    heading: "ID proof:",
    content: `Institutional ID/Organisation ID is compulsory for all visitors.`,
  },
  {
    heading: "Supervision:",
    content: `Teachers and supervisors must always remain with their students to maintain a safe and fun learning environment. Minimum ratio for student supervision: 10 kids: 1 adult`,
  },
  {
    heading: "Cancellation policy:",
    content: `No refunds will be given for notice of cancellations less than a week before the scheduled visit.`,
  },
];
  
export default GuidelinesData;
