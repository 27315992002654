const cardData = [
  {
    id: "indianMinds",
    linkType: "internal",
    linkURL: "/indian-minds",
    buttonText: "Indian Minds",
  },
  {
    id: "virtualZone",
    linkType: "external",
    linkURL: "https://paraminnovation.org/virtual-zone/",
    buttonText: "Virtual Zone",
  },
  {
    id: "scienceApp",
    linkType: "internal",
    linkURL: "/Mathemartica",
    buttonText: "Mathemartica",
  },
  {
    id: "rangolify",
    linkType: "external",
    linkURL: "https://paraminnovation.org/rangolify/",
    buttonText: "Rangolify",
  },
  {
    id: "fos",
    linkType: "internal",
    linkURL: "/fields-of-science",
    buttonText: "Fields of Science",
  },
  {
    id: "equGallery",
    linkType: "internal",
    linkURL: "/equational-gallery",
    buttonText: "Equational Gallery",
  },
  {
    id: "gamingZone",
    linkType: "internal",
    linkURL: "/gaming-zone",
    buttonText: "Gaming Zone",
  },
  
  {
    id: "evolutionSci",
    linkType: "internal",
    linkURL: "/timeline-of-reality",
    buttonText: "Timeline of Reality",
  },
  {
    id: "funSci",
    linkType: "internal",
    linkURL: "/fun-with-science",
    buttonText: "Fun Science Online",
  },
  {
    id: "parsec",
    linkType: "internal",
    linkURL: "/parsec",
    buttonText: "PARSEC Jayanagar",
  },
  {
    id: "avatar",
    linkType: "internal",
    linkURL: "/avatar",
    buttonText: "Param Science Avatar Creator",
  },

];


export default cardData;